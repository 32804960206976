import React, { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import { mobile } from 'styles/breakpoints';

interface BenefitsPublicationProps {
  title: string;
  img: string;
  subTitle: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  @media ${mobile} {
    margin: 1.5rem 1rem;
  }
`;

const StyledTitle = styled(Text)`
  color: #28293d;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.75rem */
  letter-spacing: -0.01375rem;
`;

const StyledSubtitle = styled(Text)`
  color: rgba(40, 41, 61, 0.64);
  font-family: 'Open Sans';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.94381rem; /* 75.506% */
  letter-spacing: -0.01375rem;
  @media ${mobile} {
    font-size: 1rem;
  }
`;

const Line = styled.div`
  height: 0.03931rem;
  background-color: rgba(0, 0, 0, 0.04);
  width: 100%;
  margin: 0.63rem 0;
  @media ${mobile} {
    margin: 1rem 0;
  }
`;

const StyledImage = styled(DynamicImage)`
  width: 100%;
  max-width: 21.3rem;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
`;

export const BenefitsPublication: FC<BenefitsPublicationProps> = ({
  title,
  img,
  subTitle,
}) => (
  <Container>
    <StyledTitle>{title}</StyledTitle>
    <Line />
    <StyledImage src={img} alt="publications" />
    <Line />
    <StyledSubtitle dangerouslySetInnerHTML={{ __html: subTitle }} />
  </Container>
);
